@import "./datauris";

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body #root {
  overflow-x: hidden;
}

.site-page {
  min-height: calc(100vh - 88px);
}

.carousel {
  .__carousel-item {
    display: none;

    &.active {
      display: block;
    }
  }

  .__carousel-nav {
    display: flex;
    margin-top: 1em;
    gap: 0.5rem;

    .__prev,
    .__next {
      // position: absolute;
      top: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      transition: all 0.25s ease;

      span {
        width: 100%;
        height: 100%;
      }
    }

    .__prev {
      left: 5%;

      // &:hover {
      //   margin-left: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M30.9736 22.951H19.5006L24.5156 17.806C24.7092 17.6044 24.8173 17.3356 24.8173 17.056C24.8173 16.7765 24.7092 16.5077 24.5156 16.306C24.4222 16.208 24.3098 16.13 24.1854 16.0767C24.0609 16.0233 23.927 15.9958 23.7916 15.9958C23.6562 15.9958 23.5222 16.0233 23.3977 16.0767C23.2733 16.13 23.161 16.208 23.0676 16.306L16.3006 23.257C16.1078 23.4566 16.0001 23.7231 16.0001 24.0005C16.0001 24.278 16.1078 24.5445 16.3006 24.7441L23.0676 31.692C23.161 31.7901 23.2733 31.8681 23.3977 31.9214C23.5222 31.9748 23.6562 32.0022 23.7916 32.0022C23.927 32.0022 24.0609 31.9748 24.1854 31.9214C24.3098 31.8681 24.4222 31.7901 24.5156 31.692C24.7083 31.4925 24.8161 31.226 24.8161 30.9485C24.8161 30.6711 24.7083 30.4046 24.5156 30.205L19.5006 25.0601H30.9736C31.2476 25.0516 31.5076 24.9368 31.6985 24.74C31.8894 24.5431 31.9961 24.2797 31.9961 24.0055C31.9961 23.7314 31.8894 23.468 31.6985 23.2711C31.5076 23.0743 31.2476 22.9595 30.9736 22.951Z' fill='white'/%3E%3C/svg%3E%0A") center/contain no-repeat;
      }
    }

    .__next {
      right: 5%;

      // &:hover {
      //   margin-right: -5px;
      // }
      span {
        background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25Z' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M17.0264 25.049H28.4994L23.4844 30.194C23.2908 30.3956 23.1827 30.6644 23.1827 30.944C23.1827 31.2235 23.2908 31.4923 23.4844 31.694C23.5778 31.792 23.6902 31.87 23.8146 31.9233C23.9391 31.9767 24.073 32.0042 24.2084 32.0042C24.3438 32.0042 24.4778 31.9767 24.6023 31.9233C24.7267 31.87 24.839 31.792 24.9324 31.694L31.6994 24.743C31.8922 24.5434 31.9999 24.2769 31.9999 23.9995C31.9999 23.722 31.8922 23.4555 31.6994 23.2559L24.9324 16.308C24.839 16.2099 24.7267 16.1319 24.6023 16.0786C24.4778 16.0252 24.3438 15.9978 24.2084 15.9978C24.073 15.9978 23.9391 16.0252 23.8146 16.0786C23.6902 16.1319 23.5778 16.2099 23.4844 16.308C23.2917 16.5075 23.1839 16.774 23.1839 17.0515C23.1839 17.3289 23.2917 17.5954 23.4844 17.795L28.4994 22.9399H17.0264C16.7524 22.9484 16.4924 23.0632 16.3015 23.26C16.1106 23.4569 16.0039 23.7203 16.0039 23.9945C16.0039 24.2686 16.1106 24.532 16.3015 24.7289C16.4924 24.9257 16.7524 25.0405 17.0264 25.049Z' fill='white'/%3E%3C/svg%3E%0A") center/contain no-repeat;
      }
    }
  }

  // .control-arrow {
  //   &:hover {
  //     background: #28b0a6;
  //   }
  // }

  &.carousel-food {
    .__carousel-nav {
      position: absolute;
      left: 11em;
      top: 63%;
      z-index: 2;
    }

    .content {
      // padding-inline: 5em;
      overflow: hidden;
    }
  }
}

@media (hover: none) and (pointer: coarse) {

  /* ... */
  .carousel {
    .__carousel-content {
      opacity: 0;

      &.show {
        opacity: 1;
        transition: opacity 2s ease-out;
      }
    }
  }
}

/* HAMBURGER MENU */
.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 75vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.__button {
  text-align: center;
  transition: all 0.5s;
  background-size: 200% auto;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  &.button-1 {
    background-image: linear-gradient(to left,
        rgba(156, 18, 18, 0.2) 30%,
        rgba(255, 255, 255, 0.2) 40%,
        rgba(156, 18, 18, 0.315) 100%);
    color: white;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0px 0px 15px 0px rgb(244, 172, 172),
        0px 0px 40px 0px rgba(255, 49, 42, 0.421);
      border: 2px solid;
      border-color: rgba(255, 255, 255, 0.7);
      background-position: right center;
    }
  }

  &.button-2 {
    box-shadow: 0 0 20px #eee;
    border: 1px solid;
    border-color: transparent;
    flex: 1 1 auto;
    background-image: linear-gradient(to left,
        rgba(156, 18, 18, 0.7) 0%,
        rgba(240, 100, 100, 0.5) 51%,
        rgba(156, 18, 18, 0.7) 100%);
    color: white;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0px 0px 15px 0px rgba(156, 18, 18, 1),
        0px 0px 30px 0px rgba(255, 49, 42, 0.421);
      border: 1px solid;
      border-color: rgba(255, 222, 193, 0.846);
      background-position: right center;
    }
  }

  &.button-3 {
    border: 1px solid;
    border-color: transparent;
    flex: 1 1 auto;
    background-image: linear-gradient(to left,
        rgba(156, 18, 18, 0) 0%,
        rgba(240, 100, 100, 0) 51%,
        rgba(156, 18, 18, 0) 100%);
    text-shadow: 0px 0px 1px #fd3535;

    &:hover {
      box-shadow: 0px 0px 20px 0px rgb(239, 164, 164);
      border: 1px solid;
      // border-radius: 1.25rem;
      border-color: rgba(248, 129, 129, 0.846);
      background-position: right center;
      text-shadow: 0px 0px 1px #fd3535;
      color: #891111;
    }

    &.button-3-2 {
      &:hover {
        box-shadow: 0px 0px 40px 0px rgba(248, 129, 129, 0.846);
        color: #9c1212;
      }
    }
  }

  &.button-4 {
    border: 2px solid #9c1212;
    flex: 1 1 auto;
    // background-image: linear-gradient(to left, rgba(156,18,18, 0) 0%, rgba(255, 49, 42, 0.421) 51%, rgba(156,18,18, 0) 100%);

    &:hover {
      box-shadow: 0px 0px 15px 2px rgba(255, 49, 42, 0.921);
      border: 2px solid;
      border-color: rgba(255, 62, 62, 1);
      background-position: right center;
      text-shadow: 0px 0px 1px rgb(249, 140, 140);
      color: rgb(255, 253, 253);
    }
  }

  &:hover {
    // transform: scale(1.025);
  }
}



.jlr-profile {
  width: 500px;

  img {
    position: relative;
    right: 10%;
  }
}

.text-title {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
// @media (min-width: 1367px) {
//   #travel {
//     .carousel-indicator {
//       margin-top: -75px;
//     }
//   }
// }
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  @media (max-width: 1366px) and (min-width: 768px) {
    .travel-footer {
      position: fixed;
    } 
  }
 
}
@media (max-width: 1366px) and (min-width: 768px) {
  #travel {
    .travel-banner {
      // height: calc(100vh - 152px);
    }
    .carousel-indicator {
      height: 120px;
      margin-top: -120px;
    }
  }
}

// @media (orientation: landscape) and (min-height: 780px) {
// @media (min-height: 767px) {
@media (max-width: 1200px) {
  .jlr-profile {
    width: 550px;
    img {
      right: 25%;
    }
  }
}
@media (max-width: 1024px) {
  .jlr-profile {
    width: 500px;
    img {
      right: 27%;
    }
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  #about {
    .grid {
      & > div {
        grid-column: span 12 / span 12;
        // border: 1px solid red;

      }
    }
  }
}
@media (orientation: landscape) and (min-height: 900px) {
  .jlr-profile {
    width: 768px;

    img {
      right: 35%;
    }
  }
}

@media (orientation: portrait) and (min-height: 960px) {
  .jlr-profile {
    width: 600px;

    img {
      right: 25%;
      object-fit: contain;
      object-position: top;
    }
  }
}

@media (orientation: portrait) and (min-height: 1050px) {
  .jlr-profile {
    width: auto;

    img {
      right: 0%;
      object-fit: contain;
      object-position: bottom;
    }
  }
  #spaces {
    .text-title {
      font-size: 13px;
    }
    .text-subtitle {
      font-size: 12px;
    }
  }
}

@media (orientation: landscape) and (max-height: 725px) {
  .jlr-profile {
    // width: 445px;
  }
}

@media (orientation: portrait) and (max-height: 1024px) {
  .jlr-profile {
    width: 400px;
  }
  #travel {
    .grid {
      & > div {
        grid-column: span 12 / span 12;
      }
    }

    .control-dots {
      display: none;
    }
    .carousel-indicator {
      width: 100%;
      position: absolute;
      // top: 1025px;
      top: 858px;
    }
  }
}

.__aside {
  transform: translateX(100%);
  opacity: 0.5;

  &.active {
    transform: translateX(0%);
    opacity: 1;
  }
}


@keyframes fadeLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.25);
  }

  100% {
    transform: scaled(1);
  }
}


.fade-left {
  animation: fadeLeft 0.5s ease-in;
}

.fade-zoomout {
  animation: zoomOut 3s linear 0.25s;
}

#about {
  .title {
    // animation: fadeLeft 0.5s ease-in 0.5s;
  }

}

#spaces {
  .carousel-slider {
    overflow: initial;
  }
}

#spacesInner {
  .carousel-root {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
  }

  .carousel {
    &:first-child {
      width: 75% !important;
    }

    &:last-child {
      width: 23%;
      position: absolute;
      top: 0;
      height: 100%;
      overflow-y: auto;
    }

    left: 0;

    .thumbs-wrapper {
      margin: 0 !important;

      .control-arrow {
        display: none;
      }
    }

    .thumbs {

      li {
        display: block;
        width: 100% !important;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .carousel-status {
      top: initial;
      bottom: 0.75rem;
      right: 1rem;
      background-color: rgba(255, 255, 255, 0.75);
      color: #000000;
      text-shadow: none;
      padding-left: 15px;
      width: 100px;
      font-size: 14px;
      font-weight: medium;
      border-radius: 20px;
      text-align: center;
      letter-spacing: -1px;

      &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.2 0C0.54218 0 0 0.558127 0 1.23529V12.7647C0 13.4419 0.54218 14 1.2 14H16.8C17.4578 14 18 13.4419 18 12.7647V1.23529C18 0.558127 17.4578 0 16.8 0H1.2ZM1.2 0.823529H16.8C17.0281 0.823529 17.2 1.00046 17.2 1.23529V11.0789L13.6938 7.12847C13.6133 7.04001 13.493 6.99175 13.375 6.99979C13.2743 7.00542 13.1758 7.05287 13.1063 7.12847L10.2439 10.2554L6.10008 5.488C6.0118 5.38425 5.8704 5.33117 5.73758 5.35289C5.6493 5.36737 5.56649 5.41401 5.50634 5.48157L0.80014 10.6865V1.23524C0.80014 1.00041 0.972016 0.823478 1.20014 0.823478L1.2 0.823529ZM10.4 2.88235C9.5211 2.88235 8.8 3.62466 8.8 4.52941C8.8 5.43416 9.5211 6.17647 10.4 6.17647C11.2789 6.17647 12 5.43416 12 4.52941C12 3.62466 11.2789 2.88235 10.4 2.88235ZM10.4 3.70588C10.8469 3.70588 11.2 4.06939 11.2 4.52941C11.2 4.98944 10.8469 5.35294 10.4 5.35294C9.95312 5.35294 9.6 4.98944 9.6 4.52941C9.6 4.06939 9.95312 3.70588 10.4 3.70588ZM5.7938 6.37597L11.7062 13.1765H1.2C0.97188 13.1765 0.8 12.9995 0.8 12.7647V11.8961L5.7938 6.37597ZM13.3938 8.01661L17.2 12.3014V12.7647C17.2 12.9995 17.0281 13.1764 16.8 13.1764H12.7812L10.7812 10.873L13.3938 8.01661Z' fill='%23212529'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 19px;
        height: 14px;
        position: absolute;
        left: 12%;
        top: 50%;
        transform: translateY(-50%);
      }

      &.carousel-slider {
        .control-arrow {
          &.control-prev {
            left: 1rem;
          }

          &.control-next {
            right: 1rem;
          }
        }
      }
    }
  }
}

#travel,
#spaces {
  .content-wrapper {
    position: relative;
  }

  .carousel-indicator {
    width: 50%;
    margin-left: auto;
    position: relative;
    display: flex;
    justify-content: center;
    .control-dots {
      // width: fit-content;
      // left: 72%;
      // margin: 0;
      // bottom: 5%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      li {
        box-shadow: none;
        width: 14px;
        height: 14px;
        background-color: #b4b2b2;
        transition: opacity .25s ease-in;
        opacity: .3;
        filter: alpha(opacity=30);
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        margin: 0 8px;
        &.selected {
          opacity: 1;
        }
      }
    }

    .carousel-indicator-nav {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-left: auto;
      .control-arrow {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(226, 223, 223, 0.656);
        opacity: 1;
        top: initial;
        position: relative;
  
        &:not(.control-disabled) {
          background: #28b0a6;
  
          &:hover {
            background: #28b0a6;
          }
        }
  
        &:before {
          content: '';
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          border-width: 0 !important;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
        }
        &.control-prev {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.46954 14.4866L1.88721 7.90431L8.46954 1.32275' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
  
        &.control-next {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.88867 14.9417L8.471 8.36016L1.88867 1.77783' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }

  }
}

#food {
  .slider-wrapper {
    .banner {
      filter: brightness(0.65);
    }
  }

  .content {
    position: absolute;
    z-index: 1;
    top: 29%;
    left: 12.5%;
    // padding-inline: 5em;

    .title,
    .description {
      text-shadow: 0 0 5px #2e2e2e;
    }
  }

  .carousel {
    .control-dots {
      bottom: initial;
      top: 57%;
      left: 8%;
      width: fit-content;
      z-index: 10;

      li {
        box-shadow: none;
        width: 14px;
        height: 14px;
        background-color: #ccc;
      }
    }
  }
}


#spacesInner,
#travel {


  &#travel {
    .carousel {
      &.carousel-slider {
        .control-arrow {
          bottom: 10px;
          top: initial;

          &.control-prev {
            right: 90px;
            left: initial;
          }

          &.control-next {
            right: 30px;
          }
        }
      }
    }

  }

  &#spacesInner {
    .carousel {
      &.carousel-slider {
        .control-arrow {
          bottom: initial;
          top: 50%;
          transform: translateY(-50%);

          &.control-prev {
            left: 30px;
          }

          &.control-next {
            right: 30px;
          }
        }
      }
    }

  }

  .carousel {
    &.carousel-slider {
      .control-arrow {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.35);
        opacity: 1;

        &:not(.control-disabled) {
          background: #28b0a6;

          &:hover {
            background: #28b0a6;
          }
        }

        &:before {
          border-width: 0 !important;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.control-prev {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.46954 14.4866L1.88721 7.90431L8.46954 1.32275' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &.control-next {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.88867 14.9417L8.471 8.36016L1.88867 1.77783' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }


}

#food {
  .carousel-indicators {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1em;

    .control-arrow {
      height: 30px;
      width: 30px;
      cursor: pointer;
      opacity: 1;
      transition: all 0.25s ease;
      position: relative;
      border-radius: 50%;
      margin-top: initial;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        margin: 0;
      }

      &.control-prev2 {
        &:before {
          background: url("data:image/svg+xml,%3Csvg width=%2748%27 height=%2748%27 viewBox=%270 0 48 48%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75Z%27 stroke=%27white%27 stroke-width=%271.5%27/%3E%3Cpath d=%27M30.9736 22.951H19.5006L24.5156 17.806C24.7092 17.6044 24.8173 17.3356 24.8173 17.056C24.8173 16.7765 24.7092 16.5077 24.5156 16.306C24.4222 16.208 24.3098 16.13 24.1854 16.0767C24.0609 16.0233 23.927 15.9958 23.7916 15.9958C23.6562 15.9958 23.5222 16.0233 23.3977 16.0767C23.2733 16.13 23.161 16.208 23.0676 16.306L16.3006 23.257C16.1078 23.4566 16.0001 23.7231 16.0001 24.0005C16.0001 24.278 16.1078 24.5445 16.3006 24.7441L23.0676 31.692C23.161 31.7901 23.2733 31.8681 23.3977 31.9214C23.5222 31.9748 23.6562 32.0022 23.7916 32.0022C23.927 32.0022 24.0609 31.9748 24.1854 31.9214C24.3098 31.8681 24.4222 31.7901 24.5156 31.692C24.7083 31.4925 24.8161 31.226 24.8161 30.9485C24.8161 30.6711 24.7083 30.4046 24.5156 30.205L19.5006 25.0601H30.9736C31.2476 25.0516 31.5076 24.9368 31.6985 24.74C31.8894 24.5431 31.9961 24.2797 31.9961 24.0055C31.9961 23.7314 31.8894 23.468 31.6985 23.2711C31.5076 23.0743 31.2476 22.9595 30.9736 22.951Z%27 fill=%27white%27/%3E%3C/svg%3E%0A") center/100% no-repeat;
        }
      }

      &.control-next2 {
        right: initial;

        &:before {
          background: url("data:image/svg+xml,%3Csvg width=%2748%27 height=%2748%27 viewBox=%270 0 48 48%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M24 47.25C36.8406 47.25 47.25 36.8406 47.25 24C47.25 11.1594 36.8406 0.75 24 0.75C11.1594 0.75 0.75 11.1594 0.75 24C0.75 36.8406 11.1594 47.25 24 47.25Z%27 stroke=%27white%27 stroke-width=%271.5%27/%3E%3Cpath d=%27M17.0264 25.049H28.4994L23.4844 30.194C23.2908 30.3956 23.1827 30.6644 23.1827 30.944C23.1827 31.2235 23.2908 31.4923 23.4844 31.694C23.5778 31.792 23.6902 31.87 23.8146 31.9233C23.9391 31.9767 24.073 32.0042 24.2084 32.0042C24.3438 32.0042 24.4778 31.9767 24.6023 31.9233C24.7267 31.87 24.839 31.792 24.9324 31.694L31.6994 24.743C31.8922 24.5434 31.9999 24.2769 31.9999 23.9995C31.9999 23.722 31.8922 23.4555 31.6994 23.2559L24.9324 16.308C24.839 16.2099 24.7267 16.1319 24.6023 16.0786C24.4778 16.0252 24.3438 15.9978 24.2084 15.9978C24.073 15.9978 23.9391 16.0252 23.8146 16.0786C23.6902 16.1319 23.5778 16.2099 23.4844 16.308C23.2917 16.5075 23.1839 16.774 23.1839 17.0515C23.1839 17.3289 23.2917 17.5954 23.4844 17.795L28.4994 22.9399H17.0264C16.7524 22.9484 16.4924 23.0632 16.3015 23.26C16.1106 23.4569 16.0039 23.7203 16.0039 23.9945C16.0039 24.2686 16.1106 24.532 16.3015 24.7289C16.4924 24.9257 16.7524 25.0405 17.0264 25.049Z%27 fill=%27white%27/%3E%3C/svg%3E%0A") center/100% no-repeat;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #travel {
    .control-dots {
      display: none;
    }
    .carousel-indicator {
      width: 100%;
      position: absolute;
      // top: 1025px;
      top: 858px;
    }
  }
}
@media screen and (min-width: 575px) {
  #spacesInner {
    .carousel {
      .thumbs {
        transform: none !important;
      }
    }
  }
}


@media (max-width: 1024px) and (orientation: portrait) and (min-height: 992px) {
  #travel {
    .control-dots {
      display: none;
    }

    .grid {
      & > div {
        grid-column: span 12 / span 12;
      }
    }

    .travel-banner {
      min-height: auto;
      height: 768px;
      object-fit: cover;
      object-position: center;
    }

    .content {
      // margin-left: 0;
      // min-height: initial;
    }
    .carousel-indicator {
      width: 100%;
      position: absolute;
      top: 31.5%;
    }
    .travel-gallery {
      gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      margin-left: 0;
    }
    .p-portrait-remove {
      padding: 0;
    }
  }
}
@media (max-width: 992px) and (orientation: portrait) {
  #travel {
    .carousel-indicator {
      top: 29.25%;
    }
  }
}
// @media (max-width: 768px) and (orientation: portrait) {
//   #travel {
//     .carousel-indicator {
//       top: 35%;
//     }
//   }
// }

@media screen and (max-width: 767px) {
  .carousel {
    .__carousel-nav {

      .__prev,
      .__next {
        width: 30px;
        height: 30px;
      }

      .__prev {
        left: 2% !important;
      }

      .__next {
        right: 2% !important;
      }
    }
  }

  #travel {
    .control-dots {
      display: none;
    }
    .carousel-indicator {
      width: 100%;
      position: absolute;
      top: 675px;
    }
  }

  #spacesInner,
  #travel {
    .carousel {
      &.carousel-slider {
        .control-arrow {
          bottom: initial;
          top: 18.65%;

          &.control-prev {
            left: 80%;
          }

          &.control-next {
            left: 90%;
          }
        }
      }
    }
  }

  #food {
    .content {
      // padding-inline: 2em;
    }

    .carousel {
      .control-dots {
        // left: 2em;
      }
    }
  }

  #spaces {
    .slider {

      // flex-direction: column;
      .slide .slide-inner {
        // margin-bottom: 1rem;
      }
    }
  }

  .jlr-profile {
    max-width: 100%;
    img {
      right: initial;
    }
  }
}

@media screen and (max-width: 575px) {
  .carousel {
    &.carousel-food {
      .__carousel-nav {
        position: absolute;
        left: 2em;
        top: 50%;
        z-index: 2;
      }
    }
  }

  #spacesInner,
  #travel {
    .carousel {
      &.carousel-slider {
        .control-arrow {
          bottom: initial;
          top: 19.25%;

          &.control-prev {
            left: 72%;
          }

          &.control-next {
            left: 85%;
          }
        }
      }
    }
  }

  #spacesInner {
    .carousel-root {
      display: block;
      flex-direction: row-reverse;
      gap: 15px;
    }

    .carousel {
      &:first-child {
        width: 100% !important;
      }

      &:last-child {
        width: 100%;
        position: relative;
        top: 0;
        height: 100%;
        overflow-y: auto;
      }

      left: 0;

      .thumbs-wrapper {
        margin: 0 !important;
      }

      .thumbs {

        li {
          display: inline-block;
          width: 80px !important;

          &:not(:last-child) {
            margin-bottom: 0px;
          }
        }
      }

    }
  }


  .carousel {
    &.carousel-food {
      .__carousel-nav {
        left: 3em;
        top: 55%;
      }

    }

  }

  #food {
    .content {
      // padding-inline: 2em;
    }

    .carousel {
      .control-dots {
        top: 54%;
      }
    }

  }

}