@import './variables';

@mixin li {
    color: #ffffff;
    font-size: 16px;
    padding: .25rem;
    font-weight: medium;
}

.menu {
    gap: 3.5em;
}
.menuItem {
    transition: all 0.25s;
    &:hover, &:focus {
        transform: scale(1.01) translateY(-2px);
    }
    a {
        @include li;
        display: inline-block;
    }
    
}

.getInTouchBtn {
    @include li;
    &:hover, &:focus {
        transform: scale(1.05) translateY(-2px);
    }
}


@media (max-width: 1080px) {
    .menu {
        gap: 1rem;
    }
}