@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Ghaitsa;
  src: url(./fonts/ghaitsa/Ghaitsa.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: Poppins, 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: normal;
}
body {
  background-color: #FFFFFF;
}

h1,h2,h3,h4,h5,h6,.font-ghaitsa {
  font-family: Poppins, 'sans-serif';
}

.font-heading {
  font-family: adineuePROCyr-BoldWeb, sans-serif;
}


section {
  /* min-height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.t360-skip-links {
  visibility: hidden;
}


@media (max-width: 1366px) and (min-width: 1366px) {
  .video-container > div {
    aspect-ratio: 16/9 !important;
    top: 51% !important;
  }
}
@media (max-width: 1024px) and (min-width: 1024px) {
  .video-container > div {
    aspect-ratio: 16/9 !important;
    top: 51% !important;
  }
}